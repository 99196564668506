<template>
  <div class="play_view">
    <el-alert type="success">
      <template #title>
        <div>
          <div>因为热爱，所以编写。想想，当时做个游戏开发也挺不错(*^▽^*)</div>
        </div>
      </template>
    </el-alert>
    <el-scrollbar>
      <el-skeleton
        :rows="5"
        :loading="loading"
        animated
        style="background: #fff"
      >
        <template #default>
          <el-row v-for="item in data" :key="item.href">
            <el-col :span="8">
              <el-image lazy :src="item.src">
                <template #placeholder>
                  <div class="image-slot">
                    加载中<span class="dot">...</span>
                  </div>
                </template>
                <template #error>
                  <div class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </template>
              </el-image>
            </el-col>
            <el-col :span="12">
              <div style="vertical-align: middle; display: inline-block">
                <h5>{{ item.title }}</h5>
                <p
                  style="line-height: 20px; font-size: 12px"
                  v-html="item.docs"
                ></p>
              </div>
            </el-col>
            <el-col :span="4">
              <a
                target="__blank"
                :href="item.href"
                v-if="item.title == '小霸王'"
              >
                去玩
              </a>

              <el-popover
                v-if="item.title != '小霸王'"
                placement="left-end"
                :width="200"
                trigger="click"
              >
                <el-row>
                  <el-col :span="8">体验码：</el-col>
                  <el-col :span="16">
                    <el-input v-model="item.code" size="mini"></el-input>
                  </el-col>
                </el-row>
                <div style="text-align: right; margin-top: 8px">
                  <el-button type="primary" size="mini" @click="handelLink"
                    >确定</el-button
                  >
                </div>
                <template #reference>
                  <a href="javascript:;"> 去玩 </a>
                </template>
              </el-popover>
            </el-col>
          </el-row>
        </template>
      </el-skeleton>
    </el-scrollbar>
  </div>
</template>

<script>
import { ref } from "vue";
import { ElMessage } from "element-plus";
let msg = [
  "验证码有误 😑",
  "别试了，宝 😄",
  "对不起，你不是内测玩家。🤣",
  "😁",
];
export default {
  name: "play",
  setup() {
    const loading = ref(true);
    let code = ref("");
    const data = ref([
      {
        title: "小霸王",
        docs: "小霸王，其乐无穷啊",
        href: "http://fc.my71.top",
        code: "",
        src: "https://pic1.zhimg.com/6e6bd40d5c404246ab8900f6fbb9fae3_1440w.jpg?source=172ae18b",
      },

      {
        title: "飞机大战",
        // docs: "vue3 Renderer + pixijs 实现的·生存类·游戏 ",
        docs: `
          <div>整体实现方式，以‘游戏时长’为主线。当游戏进行到一定时间后，创建对应‘元素’。这样做避免硬性编码，代码的扩展能力、游戏多变性、等能力大大提升。</div>
          <div>怪物、场景、特效、等封装成类。便于实时创建。难点方面，临界值判断、性能优化。总的来说，还不错。哈哈哈</div>
        `,
        code: "",
        href: "http://fc.my71.top",
        src: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.mp.itc.cn%2Fupload%2F20160416%2Fb2dfe8ef37904985b656869ac9a28c11_th.jpg&refer=http%3A%2F%2Fimg.mp.itc.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1629454848&t=f91f67eab6815a811a5cbf7cece576a4",
      },

      {
        title: "合金dan头",
        docs: "还有些问题",
        // docs: "react18 实现的“闯关类”游戏 ",
        code: "",
        href: "http://fc.my71.top",
        src: "http://d-image.i4.cn/i4web/image/upload/20181228/1545980021164050278.jpg",
      },
    ]);
    setTimeout(() => {
      loading.value = false;
    }, 300);

    // 跳转验证
    const handelLink = () => {
      ElMessage.warning(msg[Math.round(Math.random() * 3)]);
    };
    return { data, loading, code, handelLink };
  },
};
</script>

<style>
.play_view .el-row {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 0;
  margin-bottom: 10px;
}
.play_view .el-col-8 {
  text-align: center;
}
.play_view .el-col-8 img {
  max-width: 300px;
  width: 80%;
  vertical-align: middle;
}
.play_view .el-col-4 {
  text-align: center;
  line-height: 50px;
}
.play_view .el-col-4 a {
  transform: translateY(50px);
  color: rgb(53, 73, 255);
  border: 1px solid rgb(53, 73, 255);
  font-size: 12px;
  padding: 2px 4px;
  border-radius: 2px;
}
.play_view h5 {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 2px;
}
.play_view p {
  font-size: 14px;
  color: rgb(128, 126, 126);
}
</style>